<template>
  <div class="container">
    <div class="frontmatter-markdown text-center">
      <p>Below are some projects made in my free time.</p>
    </div>
    <hr />
    <div class="grid-3_xs-1_sm-2_md-2" v-if="projects.length">
      <div
        v-for="(project, index) in projects"
        :key="index"
        class="col center"
      >
        <a :href="project.github_url" target="_blank">
          <vs-card
            type="5"
          >
            <template #title>
              <h3>{{ project.name }}</h3>
            </template>
            <template #text>
              <p>
                {{ project.description }}
              </p>
            </template>
            <template #img>
              <img :src="project.cover_url" />
            </template>
            <template #interactions>
              <vs-tooltip right>
                <vs-button size="large" :color="project.status === 'PRIVATE' ? 'danger' : (project.is_complete === '0' ? 'warn' : 'success')" icon :href="project.github_url" blank>
                  <i class="bx bxl-github"></i>
                </vs-button>
                <template #tooltip>
                  Github
                </template>
              </vs-tooltip>
            </template>
          </vs-card>
        </a>
      </div>
    </div>
    <Nothing v-else />

    <div class="frontmatter-markdown text-center">
      <p>Online demos</p>
    </div>
    <hr />
    <div class="grid-4_xs-1_sm-2_md-2" v-if="demos.length">
      <div
        :key="index"
        v-for="(demo, index) in demos"
        class="col w-full"
      >
        <ExchangeLink
          :link="demo.link"
          :title="demo.title"
          :desc="demo.desc"
          class="center"
        />
      </div>
    </div>

  </div>
</template>

<script>
import Nothing from '@/components/Nothing.vue'
import ExchangeLink from '@/components/ExchangeLink.vue'

export default {
  name: 'ViewProjects',
  components: {
    Nothing,
    ExchangeLink
  },
  data: function () {
    return {
      projects: [],
      demos: this.getConfig('demos.json').demos
    }
  },
  mounted: function () {
    this.changeTitle('Projects')
  },
  created () {
    this.request.GET('/project/get')
      .then(res => {
        this.projects = res.data
      })
  }
}
</script>
